import { nanoid } from "nanoid";
import { LoanScreens } from "services/ApplicationScreens";

const initialState = {
  stage_number: 0,
  autovertId: "",
  Authorization: "",
  application_stage: LoanScreens.LANDING_PAGE,
  application_original_stage: LoanScreens.LANDING_PAGE,
  selectedPlan: {},
  form: {},
  plan: {},
  activePlans: [],
  activeAsset: {},
  dealerApplication: {},
  assetPrice: {},
  comparisonList: []
};

const lease = (state = initialState, { payload, type }) => {
  switch (type) {
    case "SET_ASSET_MATCHING_LOAN_PLANS":
      return {
        ...state,
        activePlans: payload,
      };

    case "SET_LOAN_ASSET_PRICING":
      return {
        ...state,
        assetPrice: payload,
      };

    case 'REMOVE_COMPARISON_ITEM':
      return {
        ...state,
        comparisonList: state.comparisonList.filter(item => item.comparisonId !== payload)
      }

    case "SET_COMPARISON_LIST":

      // Avoid dedupe of tenure, rate, and downpayment
      const filteredList = state.comparisonList.filter((item) => {
        return item.tenure !== payload.tenure || item.rate !== payload.rate || item.downpayment !== payload.downpayment || item.plan.plan.id !== payload.plan.plan.id;
      })

      const randomId = nanoid();

      return {
        ...state,
        comparisonList: [...filteredList, { ...payload, comparisonId: randomId }],
      };

      case 'UPDATE_COMPARISON_LIST': {
        const updatedComparisonList = state.comparisonList.map(item =>
          item.comparisonId === payload.comparisonId ? { ...item, ...payload } : item
        );
        return {
          ...state,
          comparisonList: updatedComparisonList,
        };
      }

    case "SET_DEALER_LOAN_APPLICATION":
      return {
        ...state,
        dealerApplication: payload,
      };

    case "SET_ASSET_INTO_LOAN_PLANS":
      return {
        ...state,
        plan: { ...state.plan, assets: payload },
      };

    case "SET_LOAN_ACTIVE_ASSET":
      return {
        ...state,
        activeAsset: payload,
      };

    case "SET_LOAN_TOKEN_AND_AUTOVERT_ID":
      return {
        ...state,
        autovertId: payload.autovertId,
        Authorization: payload.Authorization,
      };

    case "UPDATE_LOAN_PACKAGES":
      return { ...state, plan: payload };

    case "UPDATE_LOAN_ORIGINAL_STAGE":
      return { ...state, application_original_stage: payload };

    case "SET_LOAN_PROVIDER":
      return { ...state, selectedPlan: payload };

    case "UPDATE_LOAN_FORM":
      return { ...state, form: { ...state.form, ...payload } };

    case "INCREMENT_LOAN_STAGE_NUMBER":
      return { ...state, stage_number: state.stage_number + 1 };

    case "DECREMENT_LOAN_STAGE_NUMBER":
      return { ...state, stage_number: state.stage_number - 1 };

    case "CHANGE_LOAN_STAGE":
      return { ...state, application_stage: payload };

    case "CHANGE_LOAN_STAGE_NUMBER":
      return { ...state, stage_number: payload };

    case "SET_LOAN_PACKAGE":
      return { ...state, loan_package: payload };

    default:
      return state;
  }
};

export default lease;
